<template>
    <div class="absolute z-2 bottom-0 w-full">
        <div class="container mx-auto p-4 lg:w-1/2 xl:w-1/3">
            <div class="flex flex-no-wrap w-1/2 mx-auto justify-around">
                <div class="p-2">
                    <img
                        class="bg-white shadow-lg rounded-full w-full"
                        src="img/layout/safety-icons/safety_Nodrinks.svg"
                    />
                </div>
                <div class="p-2">
                    <img class="bg-white shadow-lg rounded-full w-full" src="img/layout/safety-icons/safety_10km.svg" />
                </div>
            </div>
            <div class="flex flex-no-wrap justify-around">
                <div class="p-2">
                    <img class="bg-white shadow-lg rounded-full w-full" src="img/layout/safety-icons/safety_Hat.svg" />
                </div>
                <div class="p-2">
                    <img
                        class="bg-white shadow-lg rounded-full w-full"
                        src="img/layout/safety-icons/safety_Glasses.svg"
                    />
                </div>
                <div class="p-2">
                    <img
                        class="bg-white shadow-lg rounded-full w-full"
                        src="img/layout/safety-icons/safety_Overal.svg"
                    />
                </div>
                <div class="p-2">
                    <img
                        class="bg-white shadow-lg rounded-full w-full"
                        src="img/layout/safety-icons/safety_Boots.svg"
                    />
                </div>
                <div class="p-2">
                    <img class="bg-white shadow-lg rounded-full w-full" src="img/layout/safety-icons/safety_Vest.svg" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SafetyIcons",
};
</script>
