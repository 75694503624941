<template>
    <div class="h-full">
        <div class="wrapper mx-auto lg:w-3/4 relative px-4">
            <div class="flex justify-center my-10" v-if="['----', 'home'].includes($route.name)">
                <router-link :to="{ name: 'test' }"
                    ><img src="img/css/aluminium-duffel-logo.webp" class="w-24"
                /></router-link>
            </div>
            <div class="flex justify-between items-center px-4" style="height: 100px" v-else>
                <router-link :to="{ name: 'test' }"
                    ><img class="w-24" src="img/css/aluminium-duffel-logo.webp"
                /></router-link>
                <language-picker v-if="!['test-languageSelect'].includes($route.name)" />
            </div>
            <div class="w-full bg-white px-4 py-6 md:px-8 md:py-7 xl:px-12 xl:py-10 rounded-lg md:shadow-lg">
                <transition mode="out-in" name="fade-in" appear>
                    <router-view />
                </transition>
            </div>

            <!--			<div class="flex justify-between mt-5">-->
            <!--				<router-link tag="button" :to="{name:'UiSystem'}" class="btn fixed bottom-0 right-0 mr-5 mb-5">UI System</router-link>-->
            <!--			</div>-->
        </div>
    </div>
</template>
<script>
import LanguagePicker from "../components/LanguagePicker";
import { ACTION_SET_ANONYMOUS_LOGIN_INFO } from "../store/constants";

export default {
    name: "Onsite",
    components: { LanguagePicker },
    data() {
        return {
            timeoutRef: 0,
        };
    },
    watch: {
        $route(to, from) {
            // console.log("Gert: reload", to.name, from.name);
            if (to.name === "test-languageSelect" && from.name === "test-complete") {
                location.reload();
            } else if (from.name === "test-overview") {
                location.reload();
            }
        },
    },

    mounted() {
        // document.addEventListener("touchstart", this.stopIdleTimer);
        // document.addEventListener("touchend", this.startIdleTimer);
    },

    created() {
        // console.log(localStorage.onsiteselectionV3, localStorage.onsitelogin);

        // Check for authentication and settings in localstorage
        if (localStorage.testlogin) this.$store.commit(ACTION_SET_ANONYMOUS_LOGIN_INFO, localStorage.testlogin);
    },
    computed: {},
    methods: {
        // startIdleTimer() {
        // 	this.timeoutRef = setTimeout(this.goToStart, 120000);
        // },
        // stopIdleTimer() {
        // 	clearTimeout(this.timeoutRef);
        // },
        // goToStart() {
        // 	this.stopIdleTimer();
        // 	if (this.$route.name !== 'onsite-languageSelect')
        // 		this.$router.push({name: 'onsite-languageSelect'})
        // }
    },
};
</script>
