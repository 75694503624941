<template>
    <!--<span style="display: none">{{$store.state.activeLanguage}}</span>-->
    <div class="form-group">
        <select v-model="activeLanguage" class="form-control appearance-none mr-4">
            <option v-for="lang in $store.state.config.supportedLanguages" :value="lang.iso" :key="lang.iso">
                {{ lang.nativeName }}
            </option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-4 w-4">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
            </svg>
        </div>
    </div>
</template>
<script>
import { ACTION_CHANGE_LANGUAGE } from "../store/constants";
// import ArrowDownIcon from 'vue-material-design-icons/MenuDown';

export default {
    name: "language-picker",
    components: {
        // ArrowDownIcon
    },
    computed: {
        activeLanguage: {
            get() {
                return this.$store.state.activeLanguage;
            },
            set(v) {
                this.$store.dispatch(ACTION_CHANGE_LANGUAGE, v);
            },
        },
    },
};
</script>
